<template>
  <div>
    <b-input-group class="justify-content-end">
      <b-form-select
          class="col-7"
          :options="getAllElements()"
          v-model="dataObj.data.elemId"
          @change="changeElemId"
      ></b-form-select>
    </b-input-group>

    <b-form-group
    >
      <label
          v-b-tooltip.hover
          :title="$t('Constructor.PropWidgets.ChangeContentActionWidget.Tooltip')"
      >{{$t('Constructor.General.Content')}} <i class="far fa-question-circle"></i></label>

      <b-form-textarea
          v-b-tooltip.hover
          :title = "$t('Constructor.PropWidgets.ChangeContentActionWidget.Tooltip')"
          :placeholder = "$t('General.Form.NoSet')"
          v-model="content"
          name="content"
          style="width: 100%"
          class="propField"
          @focusin="applyJsBlock"
          @focusout="cancelJsBlock"
      ></b-form-textarea>
    </b-form-group>
  </div>
</template>

<script>
import TagDataGetElemsHelper from "@/helpers/constructor/TagDataGetElemsHelper";
import JsBlockHelper from "@/helpers/constructor/sideBar/js/JsBlockHelper";
import HistoryHelper from '@/helpers/constructor/HistoryHelper'

export default {
  name: "ChangeContentActionWidget",
  props: ["tagData", "settingConstructor", "newAction", "dataObj", "actionIndex"],
  data() {
    return {
      showWidget: false,
      dataPattern: {
        elemId: null,
        jsBlockId: null
      }
    }
  },
  computed: {
    content: {
      get: function() {
        if (this.dataObj.data.elemId === null || this.dataObj.data.jsBlockId === null) {
          return ''
        }
        if (this.tagData.htmlElements[this.dataObj.data.elemId].jsBlocks[this.dataObj.data.jsBlockId].main.content === undefined) {
          return ''
        }
        return this.tagData.htmlElements[this.dataObj.data.elemId].jsBlocks[this.dataObj.data.jsBlockId].main.content
      },
      set: function(val) {
        this.HistorySet(this.tagData.htmlElements[this.dataObj.data.elemId].jsBlocks[this.dataObj.data.jsBlockId].main, 'content', val)
        // this.$set(this.tagData.htmlElements[this.dataObj.data.elemId].jsBlocks[this.dataObj.data.jsBlockId].main, 'content', val)
      }
    }
  },
  created() {
    if (!this.dataObj.data) {
      this.dataObj.data = this.dataPattern
    }
  },
  methods: {
    ...TagDataGetElemsHelper,
    ...JsBlockHelper,
    ...HistoryHelper,
    getAllElements() {
      if (this.dataObj.data.elemId !== null) {
        return this.getAllElementsToOptions()
      }
      return [...[{value: null, text: this.$t('General.Base.SelectItem')}], ...this.getAllElementsToOptions()]
    },
    changeElemId() {
      if (this.dataObj.data.elemId !== null && this.dataObj.data.jsBlockId !== null) {
        return false
      }
      this.dataObj.data.jsBlockId = this.createNewBlock(this.dataObj.data.elemId)
    },
    applyJsBlock() {
      this.$set(this.settingConstructor.js.jsBlockActive, this.dataObj.data.elemId, { jsBlockId: this.dataObj.data.jsBlockId })
    },
    cancelJsBlock() {
      this.$delete(this.settingConstructor.js.jsBlockActive, this.dataObj.data.elemId)
    }
  }
}
</script>

<style scoped>

</style>
